<template>
  <main class="container mx-auto flex flex-col items-center py-10 px-4">
    <h1 class="text-2xl font-bold mb-10">学習開始（タイムキーパー）</h1>
    <m-comment-panel class="mb-6">
      目標を書いて学習を始めよう！
    </m-comment-panel>
    <m-text-area
      label="今回の目標"
      name="goal"
      placeholder="例）塾の宿題を終わらせる！"
      :value="goal"
      class="mb-6"
      @input="goal = $event"
    />
    <m-comment-panel class="mb-6">
      終了予定時刻を設定しよう！ 初期値は 30
      分後の時刻が入っているので、学習の予定に合わせて変更しよう。
    </m-comment-panel>
    <m-text-field
      label="終了予定時刻"
      name="minute"
      type="time"
      :placeholder="timerEnd"
      :value="timerEnd"
      class="mb-6"
      @input="timerEnd = $event"
    />
    <p v-if="timerEnd && !validTimerEnd" class="mb-6 text-red-500 text-xs">
      ※ 1 分以下または 20 時間以上先の時刻は指定できません。
    </p>
    <p class="mb-6 text-gray-500 text-xs">
      ※
      終了予定時刻を経過したタイミングで、学習を終了するか継続するかの確認メッセージが送信されます。
    </p>
    <m-subjects-input
      class="mb-6"
      :subjects="subjects"
      :grade="studentGrade"
      @select="changeSubjects($event)"
    />
    <m-button class="mt-6" :invalid="!validData" @click="startLearning">
      学習開始
    </m-button>
  </main>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import dayjs from "dayjs";
import MCommentPanel from "@/components/MCommentPanel.vue";
import MSubjectsInput from "@/components/MSubjectsInput.vue";
import MButton from "@/components/form/MButton.vue";
import MTextArea from "@/components/form/MTextArea.vue";
import MTextField from "@/components/form/MTextField.vue";
import { LearningSubject } from "@/entities/learning";
import store from "@/store";
import { Student, StudentGrade } from "@/entities/student";
import {
  createLearningTimer,
  timeStringToUnixtime,
  isValidTimerEnd
} from "@/api/learning";
import { saveErrorLog } from "@/api/error";

@Options({
  components: {
    MCommentPanel,
    MSubjectsInput,
    MButton,
    MTextArea,
    MTextField
  },
  watch: {
    learningLoading: function () {
      this.onUpdateLoading();
    }
  }
})
export default class LearningStart extends Vue {
  goal = "";
  subjects: LearningSubject[] = [];
  timerEnd = "";
  enteredLearningMinute = false;

  get learningLoading(): boolean {
    return store.state.learningLoading;
  }

  get studentGrade(): StudentGrade {
    return store.state.student?.data.grade ?? "その他";
  }

  get validData(): boolean {
    return (
      this.goal.length > 0 && this.subjects.length > 0 && this.validTimerEnd
    );
  }

  get validTimerEnd(): boolean {
    return !!this.timerEnd && isValidTimerEnd(this.timerEnd);
  }

  changeSubjects(subjects: LearningSubject[]) {
    this.subjects = [...subjects];
  }

  async startLearning() {
    if (!this.validData || !store.state.student) {
      return;
    }

    try {
      store.commit("SET_LOADING", true);
      store.commit("SET_LOAD_TEXT", "タイムキーパー起動中");
      const timerStart = dayjs().unix();
      const timerEnd = timeStringToUnixtime(this.timerEnd, timerStart);
      const { learningDocId } = await createLearningTimer(
        store.state.student.ref,
        this.goal,
        this.subjects,
        timerStart,
        timerEnd
      );
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
      this.$router.replace(`/learning/${learningDocId}/timer`);
    } catch (e) {
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
      alert(`タイムキーパーの起動に失敗しました\n\n${e}`);
      await saveErrorLog(
        store.state.student,
        e.code,
        e.message,
        "Failed to create learning timer"
      );
      return;
    }
  }

  async created() {
    this.timerEnd = dayjs().add(30, "minute").format("HH:mm");
    this.onUpdateLoading();
  }

  onUpdateLoading() {
    if (!store.state.learningLoading) {
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
      const learningTimers = store.state.learnings.filter(
        learning =>
          learning.data.meta &&
          learning.data.meta.timer &&
          learning.data.meta.timer.start > 0
      );
      if (learningTimers.length > 0) {
        alert("現在タイムキーパーで学習時間を記録しています");
        this.$router.replace(`/learning/${learningTimers[0].ref.id}/timer`);
      }
    } else {
      store.commit("SET_LOADING", true);
    }
  }
}
</script>
